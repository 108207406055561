import React, { Component } from 'react';
import styled from 'styled-components';

// Images
import LogoImage from "../assets/images/AH-Logo-Mark.svg";

// Styled Components
const LogoContainer = styled.div`
  height: 2.4em;
  width: 2.4em;
`;

// Logo
class Logo extends Component {
  render() {
    const { className } = this.props;

    return (
      <LogoContainer className={className}>
        <img
          src={LogoImage}
          alt="Anonymous Health"
        />
      </LogoContainer>
    );
  }
}

export default Logo;
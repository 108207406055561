import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Styled Components
const CalloutTextContainer = styled.div`
  margin-bottom: 3em;
  max-width: 28em;

  &.center {
    margin-inline: auto;
    text-align: center;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &, &.no-margin-bottom {
    ${queryBelow("bigPhone", css`
      margin-bottom: 1em;
    `)}
  }


  p {
    font-size: 1.2em;
    margin-bottom: 0;
    text-wrap: balance;
  }
`;

// CalloutText
class CalloutText extends Component {
  render() {
    const { className, text } = this.props;

    return (
      <CalloutTextContainer className={className}>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </CalloutTextContainer>
    );
  }
}

export default CalloutText;
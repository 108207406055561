import React from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper/modules';

// Swiper Styles
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/free-mode';
import 'swiper/css/autoplay'; // Autoplay styles

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Images
import AJ from "../assets/images/companions/AJ.webp"
import Calvin from "../assets/images/companions/Calvin.webp"
import Chloe from "../assets/images/companions/Chloe.webp"
import Felix from "../assets/images/companions/Felix.webp"
import Fiona from "../assets/images/companions/Fiona.webp"
import Hope from "../assets/images/companions/Hope.webp"
import Julie from "../assets/images/companions/Julie.webp"
import Keiko from "../assets/images/companions/Keiko.webp"
import Ken from "../assets/images/companions/Ken.webp"
import Louis from "../assets/images/companions/Louis.webp"
import Maryam from "../assets/images/companions/Maryam.webp"
import Nadia from "../assets/images/companions/Nadia.webp"
import Pravesh from "../assets/images/companions/Pravesh.webp"
import Sofia from "../assets/images/companions/Sofia.webp"
import Tunde from "../assets/images/companions/Tunde.webp"
import Zara from "../assets/images/companions/Zara.webp"

// Components
import CalloutText from './CalloutText';

// Styled Components
const AICalloutContainer = styled.section`
  padding: 0;

  > .wrap {
    margin-bottom: 1rem;
  }

  .swiper {
    overflow: visible;

    &-wrapper {
      transition-timing-function: linear;
    }

    &-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 12em !important;
      padding: 1em 0;

      &:nth-of-type(even) {
        top: 2em;

        ${queryBelow("bigPhone", css`
          top: 1em;
        `)}
      }

      &:nth-of-type(odd) {
        top: -2em;

        ${queryBelow("bigPhone", css`
          top: -1em;
        `)}
      }
    }
  }
`;

const AICharacter = styled.img`
  position: relative;
  height: auto;
  width: 10em;
`;

const AICallout = () => {
  // List of characters for dynamic rendering
  const characters = [
    { src: AJ, alt: "AJ" },
    { src: Calvin, alt: "Calvin" },
    { src: Chloe, alt: "Chloe" },
    { src: Felix, alt: "Felix" },
    { src: Fiona, alt: "Fiona" },
    { src: Hope, alt: "Hope" },
    { src: Julie, alt: "Julie" },
    { src: Keiko, alt: "Keiko" },
    { src: Ken, alt: "Ken" },
    { src: Louis, alt: "Louis" },
    { src: Maryam, alt: "Maryam" },
    { src: Nadia, alt: "Nadia" },
    { src: Pravesh, alt: "Pravesh" },
    { src: Sofia, alt: "Sofia" },
    { src: Tunde, alt: "Tunde" },
    { src: Zara, alt: "Zara" },
  ];

  // Duplicate slides for seamless infinite scrolling
  const duplicateSlides = [...characters, ...characters];

  return (
    <AICalloutContainer>
      <div className="wrap">
        <CalloutText text="<strong><em>Chat</em></strong> with our CBT-trained AI companions anytime you need them." />
      </div>
      <Swiper
        slidesPerView="auto"
        freeMode={true}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={5000}
        grabCursor={true}
        loopAdditionalSlides={2}
        modules={[FreeMode, Autoplay]}
      >
        {duplicateSlides.map((character, index) => (
          <SwiperSlide key={index}>
            <AICharacter
              src={character.src}
              alt={`${character.alt}, an Anonymous Health AI Companion`}
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </AICalloutContainer>
  );
};

export default AICallout;
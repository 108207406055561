import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Swiper Styles
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/free-mode';
import 'swiper/css/autoplay'; // Autoplay styles

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import CBR from "../assets/images/testimonials/aht-cbr.webp";
import CH from "../assets/images/testimonials/aht-ch.webp";
import DP from "../assets/images/testimonials/aht-dp.webp";
import Josh from "../assets/images/testimonials/aht-josh.webp";
import Ruben from "../assets/images/testimonials/aht-ruben.webp";
import SAS from "../assets/images/testimonials/aht-sas.webp";
import ShawnaP from "../assets/images/testimonials/aht-shawna-p.webp";
import Tracilee from "../assets/images/testimonials/aht-tracilee.webp";
import TW from "../assets/images/testimonials/aht-tw.webp";

// Components
import CalloutText from './CalloutText';
import Testimonial from './Testimonial';

// Styled Components
const TestimonialsCalloutContainer = styled.section`
  padding: 2em 0;
`;

const TestimonialsContainer = styled.div`
  .swiper {
    overflow: visible;
    width: 100%;
    padding: 0 5% 0 calc((100vw - 58em) / 2);

    ${queryBelow("bigPhone", css`
      padding-left: 5%;
    `)}
  }

  .swiper-slide {
    display: inline-block;
    width: auto;
  }
`;

class Testimonials extends Component {
  render() {
    const { className } = this.props;

    return (
      <TestimonialsCalloutContainer className={className}>
        <div className="wrap">
          <CalloutText text="What <strong><em>real people</em></strong> have to say about their experience with Anonymous Health" />
        </div>

        <TestimonialsContainer>
          <Swiper
            slidesPerView="auto"
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            speed={200}
            grabCursor={true}
            loopAdditionalSlides={2}
            modules={[Autoplay]}
            spaceBetween={32}
          >
            <SwiperSlide>
              <Testimonial
                text="I’ve been impressed by the level of care I’ve received, and it has improved my outlook on life."
                sourceName="Ruben G."
                sourceImg={Ruben}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="They actually get to the root of the reasons why I drank. I was listened to without judgment..."
                sourceName="Shawna P."
                sourceImg={ShawnaP}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="Wonderful with building rapport... very knowledgeable and uses very inclusive language, I love that."
                sourceName="C.H."
                sourceImg={CH}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="...has been a Godsend for me. Not only helping me with alcohol dependence, but navigating some very tough challenges."
                sourceName="Josh"
                sourceImg={Josh}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="I love Anonymous Health and Shannon helped me so much!"
                sourceName="Tracilee"
                sourceImg={Tracilee}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="Amazing experience! Wonderful therapist who really helped me deal with my issues and make meaningful changes."
                sourceName="CBR"
                sourceImg={CBR}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="...I feel the most comfortable with my present team that I hadn't felt with previous therapist."
                sourceName="SAS"
                sourceImg={SAS}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="...understands where I'm coming from and is very educated on proactive skills for overcoming addictions."
                sourceName="DP"
                sourceImg={DP}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Testimonial
                text="...I feel like Anonymous Health is a breakthrough in treatment options."
                sourceName="TW"
                sourceImg={TW}
              />
            </SwiperSlide>
          </Swiper>
        </TestimonialsContainer>
      </TestimonialsCalloutContainer>
    );
  }
}

export default Testimonials;
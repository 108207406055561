import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import LogoImage from "../assets/images/AH-Logo-Mark.svg";

// Components
import AppStoreNav from "./AppStoreNav";

// Styled Components
const PersistentCTAContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 1.4em 1.4em 0 0;
  bottom: 0;
  box-shadow: 0 -1em 8em rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 1em;
  justify-content: space-between;
  left: 50%;
  padding: 2em 2em;
  position: fixed;
  transform: translate(-50%, 100%);
  transition: transform 0.4s ease-in-out;
  width: 90%;
  z-index: 4;

  ${queryBelow("tablet", css`
    max-width: 100%;
    width: 100%;
  `)}

  ${queryBelow("bigPhone", css`
    flex-direction: column;
    padding: 1.6em;
  `)}

  &.visible {
    transform: translate(-50%, 0);
  }

  &.hidden {
    transform: translate(-50%, 100%);
  }
`;

const PersistentCTAMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;

  > p {
    text-wrap: balance;
    margin-bottom: 0;

    ${queryBelow("bigPhone", css`
      text-align: center;
    `)}
  }
`;

const PersistentCTAImage = styled.img`
  height: 2.2em;
  width: 2.2em;

  ${queryBelow("bigPhone", css`
    display: none;
  `)}
`;

// PersistentCTA
class PersistentCTA extends Component {
  state = {
    visible: false,
    hidden: false,
  };

  observer = null;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    // Set up IntersectionObserver for the footer
    const footer = document.querySelector("footer"); // Adjust the selector to match your footer
    if (footer) {
      this.observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.setState({ hidden: true });
          } else {
            this.setState({ hidden: false });
          }
        },
        { threshold: 0.1 } // Trigger when at least 10% of the footer is visible
      );

      this.observer.observe(footer);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleScroll = () => {
    const heroHeight = document.querySelector(".hero")?.offsetHeight || 0.6;
    const scrollPosition = window.scrollY;

    if (scrollPosition > heroHeight && !this.state.visible) {
      this.setState({ visible: true });
    } else if (scrollPosition <= heroHeight && this.state.visible) {
      this.setState({ visible: false });
    }
  };

  render() {
    const { className } = this.props;
    const { visible, hidden } = this.state;

    return (
      <PersistentCTAContainer
        role="banner"
        className={`${className} ${visible ? "visible" : ""} ${hidden ? "hidden" : ""}`}
      >
        <PersistentCTAMessage>
          <PersistentCTAImage
            src={LogoImage}
            alt="Anonymous Health Logo"
          />
          <p className="medium">
            Begin your journey <strong><em>today</em></strong> with the Anonymous Health App.
          </p>
        </PersistentCTAMessage>
        <AppStoreNav />
      </PersistentCTAContainer>
    );
  }
}

export default PersistentCTA;
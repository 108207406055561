import React, { Component, createRef } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import CommunityLogo from "../assets/images/AH-Community-Logo.svg";
import AHCCreateAvatarImg from "../assets/images/AHC-Create-Avatar.webp";
import AHCCommunityFeedImg from "../assets/images/AHC-Community-Feed.webp";
import AHCForYouImg from "../assets/images/AHC-For-You.webp";

// Components
import CalloutText from './CalloutText';

// Styled Components
const CommunityCalloutContainer = styled.section``;

const CommunityImages = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;

  ${queryBelow("bigPhone", css`
    gap: 0;
  `)}

  img {
    max-width: 16em;
    height: auto;
    width: calc((100% / 3) - 3em);
    position: relative;
    opacity: 0;
    transform: translateY(8em);
    transition: transform 1.5s ease, opacity 0.5s ease;

    ${queryBelow("bigPhone", css`
      margin-inline: -1em;
      width: calc((100% / 3));
    `)}

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-of-type(odd) {
      top: 2em;
    }
  }
`;

const CommunityLogoImg = styled.img`
  display: block;
  height: 5em;
  margin-inline: auto;
  margin-bottom: 1rem;
  width: 5em;
`;

// CommunityCallout Component
class CommunityCallout extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      visibleImages: [false, false, false], // Tracks visibility for all three images
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.triggerSequentialFadeIn();
          this.observer.disconnect(); // Stop observing after triggering animations
        }
      },
      {
        root: null,
        threshold: 0.6, // Adjust to trigger animations earlier or later
      }
    );

    if (this.containerRef.current) {
      this.observer.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  triggerSequentialFadeIn = () => {
    const { visibleImages } = this.state;

    visibleImages.forEach((_, index) => {
      setTimeout(() => {
        this.setState((prevState) => {
          const updatedVisibleImages = [...prevState.visibleImages];
          updatedVisibleImages[index] = true;
          return { visibleImages: updatedVisibleImages };
        });
      }, index * 100);
    });
  };

  render() {
    const { visibleImages } = this.state;
    const { className } = this.props;

    return (
      <CommunityCalloutContainer className={`community-callout ${className}`} ref={this.containerRef}>
        <div className="wrap wide">
          <div className="content-frame gradient-bg">
            <CommunityLogoImg
              src={CommunityLogo}
              alt="Anonymous Health"
            />
            <CalloutText
              className="center"
              text="<strong><em>Join the community</em></strong> to connect<br/> with others on the path to betterment."
            />
            <CommunityImages>
              {[AHCCreateAvatarImg, AHCCommunityFeedImg, AHCForYouImg].map((src, index) => (
                <img
                  key={index}
                  alt={`Anonymous Health Community - ${index + 1}`}
                  src={src}
                  loading="lazy"
                  className={visibleImages[index] ? 'visible' : ''}
                />
              ))}
            </CommunityImages>
          </div>
        </div>
      </CommunityCalloutContainer>
    );
  }
}

export default CommunityCallout;
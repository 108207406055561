import React, { Component, createRef } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import AHTechniquesPlant from "../assets/images/ah-techniques-plant.webp";
import ThreeMinuteBreathing from "../assets/images/AH-Teq-3-Minute-Breathing.webp";
import CravingUrgeTimer from "../assets/images/AH-Teq-Craving-Urge-Timer.webp";
import MindfulWalking from "../assets/images/AH-Teq-Mindful-Walking.webp";

// Components
import CalloutText from './CalloutText';

// Styled Components
const TechniquesCalloutContainer = styled.section`
  background-color: #111111;
  background-image: url(${AHTechniquesPlant});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;
  margin-bottom: 2em;
  padding: 8em 0;

  ${queryBelow("bigPhone", css`
    padding: 4em 0 6em;
  `)}

  .wrap {
    flex-wrap: nowrap;
    gap: 2em;
    align-items: center;

    ${queryBelow("bigPhone", css`
      align-items: flex-start;
      flex-direction: column;
    `)}
  }
`;

const InspirationalContentImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${queryBelow("bigPhone", css`
    justify-content: center;
    margin-inline: auto;
  `)}

  img {
    max-height: 100vh;
    max-width: 12em;
    height: auto;
    margin-inline: -2em;
    width: 40%;
    position: relative;
    opacity: 0;
    transform: translateY(8em);
    transition: transform 1.5s ease, opacity 0.5s ease;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-of-type(1) {
      top: 2em;
    }

    &:nth-of-type(2) {
      top: 0em;
    }

    &:nth-of-type(3) {
      top: -2em;
    }
  }
`;

class TechniquesCallout extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      visibleImages: [false, false, false],
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.triggerSequentialFadeIn();
          this.observer.disconnect(); // Disconnect after animations start
        }
      },
      {
        root: null,
        threshold: 0.6,
      }
    );

    if (this.containerRef.current) {
      this.observer.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  triggerSequentialFadeIn = () => {
    const { visibleImages } = this.state;

    visibleImages.forEach((_, index) => {
      setTimeout(() => {
        this.setState((prevState) => {
          const updatedVisibleImages = [...prevState.visibleImages];
          updatedVisibleImages[index] = true;
          return { visibleImages: updatedVisibleImages };
        });
      }, index * 100);
    });
  };

  render() {
    const { visibleImages } = this.state;
    const { className } = this.props;

    return (
      <div className="wrap wide mobile-full" ref={this.containerRef}>
        <TechniquesCalloutContainer className={`content-frame ${className}`}>
          <div className="wrap">
            <CalloutText
              className="no-margin-bottom"
              text="Learn and practice proven CBT techniques to <strong><em>overcome cravings and urges</em></strong> in the moments that matter."
            />
            <InspirationalContentImages>
              {[MindfulWalking, CravingUrgeTimer, ThreeMinuteBreathing].map(
                (src, index) => (
                  <img
                    key={index}
                    alt={`Anonymous Health Technique Screenshot ${index + 1}`}
                    src={src}
                    loading="lazy"
                    className={visibleImages[index] ? 'visible' : ''}
                  />
                )
              )}
            </InspirationalContentImages>
          </div>
        </TechniquesCalloutContainer>
      </div>
    );
  }
}

export default TechniquesCallout;
import React, { Component, createRef } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import AHChapterFan1 from "../assets/images/AHChapterFan1.webp";
import AHChapterFan2 from "../assets/images/AHChapterFan2.webp";
import AHChapterFan3 from "../assets/images/AHChapterFan3.webp";
import AHChapterFan4 from "../assets/images/AHChapterFan4.webp";
import AHChapterFan5 from "../assets/images/AHChapterFan5.webp";

// Components
import CalloutText from './CalloutText';

// Styled Components
const ChapterCalloutContainer = styled.section`
  padding: 6em 0 0;
  overflow: hidden;

  .wrap {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2em;
  }
`;

const ChapterFanImages = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -1em;
  width: 100%;

  ${queryBelow("bigPhone", css`
    font-size: 0.5em;
    justify-content: center;
    margin-inline: auto;
  `)}

  img {
    max-height: 100vh;
    max-width: 16em;
    height: auto;
    margin-inline: -2em;
    width: 100%;
    position: relative;
    opacity: 0;
    transform: translateY(8em);
    transition: transform 1.5s ease, opacity 0.6s ease;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-of-type(1) {
      margin-inline: -3em;
      top: 7em;
      rotate: -30deg;
    }

    &:nth-of-type(2) {
      top: 2em;
      rotate: -15deg;
    }

    &:nth-of-type(4) {
      top: 2em;
      rotate: 15deg;
    }

    &:nth-of-type(5) {
      margin-inline: -3em;
      top: 7em;
      rotate: 30deg;
    }
  }
`;

class ChapterCallout extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      visibleImages: [false, false, false, false, false],
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ visibleImages: [true, true, true, true, true] });
          this.observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.6,
      }
    );

    if (this.containerRef.current) {
      this.observer.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    const { visibleImages } = this.state;
    const { className } = this.props;

    return (
      <ChapterCalloutContainer className={className} ref={this.containerRef}>
        <div className="wrap">
          <CalloutText
            className="center"
            text="<strong><em>Guided chapters</em></strong> walk you through<br/>change and recovery, step-by-step."
          />
          <ChapterFanImages>
            {[AHChapterFan1, AHChapterFan2, AHChapterFan3, AHChapterFan4, AHChapterFan5].map(
              (src, index) => (
                <img
                  key={index}
                  alt={`Chapter Illustration ${index + 1}`}
                  src={src}
                  loading="lazy"
                  className={visibleImages[index] ? 'visible' : ''}
                />
              )
            )}
          </ChapterFanImages>
        </div>
      </ChapterCalloutContainer>
    );
  }
}

export default ChapterCallout;
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Components
import AppStoreNav from "./AppStoreNav";
// import ProductHuntBadge from './ProductHuntBadge';

// Images
import AppIcon from "../assets/images/AH-App-Icon.svg"
import Instagram from "../assets/images/instagram.svg"
import TikTok from "../assets/images/tiktok.svg"
import Linkedin from "../assets/images/linkedin.svg"

// Styled Components
const FooterContainer = styled.footer`
  background-color: #111111;
  color: #FFFFFF;
  position: relative;
  z-index: 2;

  .wrap {
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;

    p {
      text-wrap: balance;
    }
  }
`

const FooterAppStoreNav = styled(AppStoreNav)`
  justify-content: center;
  margin-bottom: 4em;
`

const AppIconImage = styled.img`
  height: 4em;
  width: 4em;
  margin-inline: auto;
  margin-bottom: 1rem;
`

const SubFooter = styled.div`
  font-size: 0.875rem;
`

const SocialNav = styled.nav`
  align-items: center;
  gap: 2em;
`

const SocialLinkImage = styled.img`
  height: 2em;
  width: auto;

  &:hover {
    opacity: 0.6;
  }
`

const FooterNav = styled.nav`
  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Copyright = styled.p`
  opacity: 0.4;

  span {
    ${queryBelow("bigPhone", css`
      display: none;
    `)}
  }
`

// Footer
class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear(); // Dynamically get the current year

    return (
      <FooterContainer>
        <div className="wrap">
          <AppIconImage alt="The Anonymous Health App Icon" src={AppIcon} />
          <p className="large">Begin your journey <strong><em>today</em></strong> with the Anonymous Health App.</p>
          <FooterAppStoreNav />
        </div>
        <SubFooter>
          <div className="wrap">
            <SocialNav>
              <a href="https://www.instagram.com/anonymous.health/" target="_blank" rel="noreferrer" aria-label="Anonymous Health on Instagram">
                <SocialLinkImage alt="Anonymous Health on Instagram" src={Instagram} />
              </a>
              <a href="https://www.tiktok.com/@anonymoushealth_" target="_blank" rel="noreferrer" aria-label="Anonymous Health on TikTok">
                <SocialLinkImage alt="Anonymous Health on TikTok" src={TikTok} />
              </a>
              <a href="https://www.linkedin.com/company/anonymoushealth" target="_blank" rel="noreferrer" aria-label="Anonymous Health on LinkedIn">
                <SocialLinkImage alt="Anonymous Health on LinkedIn" src={Linkedin} />
              </a>
            </SocialNav>
            <FooterNav>
              <a href="https://www.anonymoushealth.com/privacy-policy" target="_blank" rel="noreferrer">Privacy</a>
              <a href="https://www.anonymoushealth.com/terms" target="_blank" rel="noreferrer">Terms</a>
              <a href="https://www.anonymoushealth.com/patient-notice-of-privacy-practices" target="_blank" rel="noreferrer">Patient Notice of Privacy Practices</a>
              <a href="https://app.termly.io/notify/b9ee11c1-a0f6-41cd-9ff9-0d830c003db1" target="_blank" rel="noreferrer">CA Do Not Sell/Limit Use</a>
              <a href="https://app.termly.io/notify/b9ee11c1-a0f6-41cd-9ff9-0d830c003db1" target="_blank" rel="noreferrer">DSAR</a>
              <a href="https://www.anonymoushealth.com/frequently-asked-questions" target="_blank" rel="noreferrer">FAQ</a>
              <a href="https://www.anonymoushealth.com/app#" target="_blank" rel="noreferrer">Consent Preferences</a>
            </FooterNav>
            <Copyright>Copyright &copy; {currentYear} Anonymous Health. <span>All Rights Reserved.</span></Copyright>
          </div>
        </SubFooter>
      </FooterContainer>
    );
  }
}

export default Footer;

import './App.scss';

// Components
import WelcomeBanner from './components/WelcomeBanner';
import AppHero from './components/AppHero';
import AICallout from './components/AICallout';
import ChapterCallout from './components/ChapterCallout';
import HabitsAndBehaviors from './components/HabitsAndBehaviors';
import InspirationalContentCallout from './components/InspirationalContentCallout';
import TechniquesCallout from './components/TechniquesCallout';
import Testimonials from './components/Testimonials';
import CommunityCallout from './components/CommunityCallout';
import PersistentCTA from './components/PersistentCTA';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <main>
        <WelcomeBanner />
        <AppHero />
        <AICallout />
        <ChapterCallout />
        <div className="gradient-bg">
          <HabitsAndBehaviors />
          <TechniquesCallout />
          <Testimonials />
        </div>
        <InspirationalContentCallout />
        <CommunityCallout />
        <Footer />
        <PersistentCTA />
      </main>
    </div>
  );
}

export default App;

import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import ArrowLeft from "../assets/images/arrow-left.svg";
import ProductHuntLogo from "../assets/images/Product-Hunt-Logo.svg";
import CornerCoverLeft from "../assets/images/corner-cover-left.svg";
import CornerCoverRight from "../assets/images/corner-cover-right.svg";

// Styled Components
const WelcomeBannerContainer = styled.div`
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  padding: 0.6em;
  position: relative;
  z-index: 3;

  &:after,
  &:before {
    background-repeat: none;
    background-size: 100%;
    bottom: -1.25em;
    content: "";
    height: 1.25em;
    position: absolute;
    width: 1.25em;
  }

  &:after {
    background-image: url("${CornerCoverRight}");
    right: 0;
    background-position: top right;
  }

  &:before {
    background-image: url("${CornerCoverLeft}");
    left: 0;
    background-position: top left;
  }

  .wrap {
    align-items: center;
    flex-wrap: nowrap;
    gap: 2em;
    justify-content: space-between;

    // ${queryBelow("bigPhone", css``)}
  }
`;

const WelcomeText = styled.div`
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 0.6em;
  font-size: 0.875em;
  line-height: 0;
  width: 100%;

  &, a, a span {
    margin-bottom: 0;
  }

  img {
    height: 1.4rem;
    width: 1.4rem;
  }

  a {
    &, > span {
      color: #000000;
    }
  }
`;

const ProductHuntBadge = styled.a`
  margin-left: auto;

  img {
    height: 2.5em;
    width: auto;
  }
`

const ParentLink = styled.a`
  font-size: 0.875em;
  display: flex;
  gap: 0.6em;
  align-items: center;

  > img {
    height: 0.8em;
    width: 0.8em;
  }
`

class WelcomeBanner extends Component {
  render() {
    const { className } = this.props;

    // Check URL query parameters
    const searchParams = new URLSearchParams(window.location.search);
    const refParam = searchParams.get('ref');
    const isProductHuntReferral = refParam === 'producthunt';
    const isAHWebReferral = refParam === 'ahweb';

    return (
      (isProductHuntReferral || isAHWebReferral) && (
        <WelcomeBannerContainer className={`welcome-banner ${className}`}>
          <div className="wrap">
            <WelcomeText>
              {isProductHuntReferral && (
                <>
                  <span class="mobile-hide">
                    <img src={ProductHuntLogo} alt="Product Hunt Logo" />
                  </span>
                  <span>
                    <strong>We're on Product Hunt!</strong>
                    {' '}
                    <a
                      href="https://www.producthunt.com/posts/anonymous-health"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="mobile-hide">Please support us with an upvote.</span>
                    </a>
                  </span>
                  <ProductHuntBadge href="https://www.producthunt.com/posts/anonymous-health?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-anonymous&#0045;health" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=628660&theme=light" alt="Anonymous&#0032;Health - Overcome&#0032;addictions&#0032;and&#0032;unwanted&#0032;habits | Product Hunt"/>
                  </ProductHuntBadge>
                </>
              )}
              {isAHWebReferral && (
                <ParentLink
                  href="https://anonymoushealth.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ArrowLeft} alt="back to AnonymousHealth.com" />
                  <span>back to AnonymousHealth.com</span>
                </ParentLink>
              )}
            </WelcomeText>
          </div>
        </WelcomeBannerContainer>
      )
    );
  }
}

export default WelcomeBanner;
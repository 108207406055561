import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Components
import CalloutText from './CalloutText';
import ButtonLink from './ButtonLink';

// Styled Components
const HabitsAndBehaviorsContainer = styled.section`
  .wrap {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2em;

    ${queryBelow("bigPhone", css`
      gap: 0;
    `)}
  }
`;

const HabitsAndBehaviorsList = styled.div`
  gap: 0.6em;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`

// HabitsAndBehaviors
class HabitsAndBehaviors extends Component {
  render() {
    const { className } = this.props;

    return (
      <HabitsAndBehaviorsContainer className={className}>
        <div className="wrap">
          <CalloutText className="center no-margin-bottom" text="Get help with a <strong><em>wide range</em></strong> of habits & behaviors." />
          <HabitsAndBehaviorsList>
            <ButtonLink
              text="Alcohol"
              href="https://www.anonymoushealth.com/supported-conditions/alcohol"
            />
            <ButtonLink
              text="Cannabis"
              href="https://www.anonymoushealth.com/supported-conditions/cannabis"
            />
            <ButtonLink
              text="Nicotine and Tobacco"
              href="https://www.anonymoushealth.com/supported-conditions/nicotine-and-tobacco"
            />
            <ButtonLink
              text="Opiods or Painkillers"
              href="https://www.anonymoushealth.com/supported-conditions/opioids-or-painkillers"
            />
            <ButtonLink
              text="Stimulants"
              href="https://www.anonymoushealth.com/supported-conditions/stimulants"
            />
            <ButtonLink
              text="Depressants"
              href="https://www.anonymoushealth.com/supported-conditions/depressants"
            />
            <ButtonLink
              text="Mobile Phone or Internet"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-mobile-phone-or-internet-use"
            />
            <ButtonLink
              text="Social Media"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-social-media-use"
            />
            <ButtonLink
              text="Video Games"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-video-gaming"
            />
            <ButtonLink
              text="Streaming / TV"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-video-streaming-or-tv-use"
            />
            <ButtonLink
              text="Gambling"
              href="https://www.anonymoushealth.com/supported-conditions/gambling"
            />
            <ButtonLink
              text="Pornography"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-pornography"
            />
            <ButtonLink
              text="Sexual Behavior"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-sexual-behavior"
            />
            <ButtonLink
              text="Compulsive Shopping"
              href="https://www.anonymoushealth.com/supported-conditions/compulsive-shopping"
            />
            <ButtonLink
              className="ghost"
              text="...and more."
              href="https://www.anonymoushealth.com/"
            />
          </HabitsAndBehaviorsList>
        </div>
      </HabitsAndBehaviorsContainer>
    );
  }
}

export default HabitsAndBehaviors;

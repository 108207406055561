import React, { Component } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Styled Components
const ButtonLinkContainer = styled.a`
  align-items: center;
  background-color: #111111;
  border-radius: 100em !important;
  border: 2px solid #111111;
  color: #FFFFFF;
  display: flex;
  font-weight: 800;
  padding: 1em 1.4em;

  ${queryBelow("bigPhone", css`
    font-size: 0.875em;
    padding: 0.8em 1em;
  `)}

  &.ghost {
    background-color: transparent;
    color: #111111;
  }
`;

// ButtonLink
class ButtonLink extends Component {
  render() {
    const { className, text, href } = this.props;

    return (
      <ButtonLinkContainer className={className} href={href} target="_blank">
        {text}
      </ButtonLinkContainer>
    );
  }
}

export default ButtonLink;
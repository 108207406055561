import React, { Component } from 'react';
import styled from 'styled-components';

// Styled Components
const TestimonialContainer = styled.div`
  pointer-events: none;
  user-select: none;
`;

const TestimonialText = styled.div`
  background-color: #FFFFFF;
  border-radius: 1.4em 1.4em 1.4em 0;
  box-shadow: 0 0.3em 0.9em -0.2em rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  max-width: 22rem;
  padding: 1.4em;

  p {
    font-weight: 700;
    margin-bottom: 0;

    &:after,
    &:before {
      content: '"';
    }
  }
`;

const TestimonialSource = styled.div`
  align-items: center;
  display: flex;
  gap: 1em;
`;

const TestimonialSourceName = styled.p`
  font-size: 0.875em;
  font-weight: 700;
  margin-bottom: 0;
`;

const TestimonialSourceImg = styled.img`
  aspect-ratio: 1/1;
  border-radius: 1000em;
  height: 2.5em;
  overflow: hidden;
  width: 2.5em;
`;

// Testimonial
class Testimonial extends Component {
  render() {
    const { className, text, sourceName, sourceImg } = this.props;

    return (
      <TestimonialContainer className={className}>
        <TestimonialText>
          <p>{text}</p>
        </TestimonialText>
        <TestimonialSource>
          {
            sourceImg &&
            sourceName &&
            <TestimonialSourceImg
              alt={`A photo of ${sourceName}, who gave the above testimonial snippet about the Anonymous Health app.`}
              src={sourceImg}
              loading="lazy"
            />
          }
          <TestimonialSourceName>{sourceName}</TestimonialSourceName>
        </TestimonialSource>
      </TestimonialContainer>
    );
  }
}

export default Testimonial;
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Logo from "./Logo";
import AppStoreNav from "./AppStoreNav";

// Images
import AppHeroImage from "../assets/images/AH-AI-Companion-Landing-Julie.webp"

// Styled Components
const HeroLogo = styled(Logo)`
  position: absolute;
  top: -2em;
  left: 0;

  ${queryBelow("bigPhone", css`
    left: 0.5em;
  `)}
`

const VideoContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  aspect-ratio: 1/1;
  pointer-events: none;
  max-height: 100vh;
  height: auto;
  transform: translateY(-50%);
  width: 55%;

  ${queryBelow("bigPhone", css`
    top: 4em;
    height: auto;
    transform: translateY(0);
    width: 100%;
  `)}

  iframe {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }
`

const VideoContainerPoster = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;

  img {
    height: 91%;
    width: auto;
    margin: auto;
  }
`

const AppHeroContainer = styled.section`
  .wrap {
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: 2em;
    height: calc(100vh - 8em);
    justify-content: space-between;

    ${queryBelow("bigPhone", css`
      flex-direction: column;
      height: auto;
      justify-content: center;
      text-align: center;
      gap: 0;
    `)}

    // This holds space for the video.
    > img {
      opacity: 0;
      height: auto;
      position: relative;
      width: 55%;

      ${queryBelow("bigPhone", css`
        height: auto;
        max-width: 60%;
        width: auto;
        max-height: 100%;
      `)}
    }
  }

  .app-hero-content {
    max-width: 480px;
  }
`

// AppHero
class AppHero extends Component {
  render() {
    const { className } = this.props;

    return(
      <AppHeroContainer className={`hero ${className}`}>
        <VideoContainer>
          <iframe
            src="https://player.vimeo.com/video/1036519932?autoplay=1&muted=1&loop=1&background=1&quality=1080p"
            title="Background Video"
            allow="autoplay"
            frameborder="0"
          ></iframe>
          <VideoContainerPoster>
            <img
              src={AppHeroImage}
              alt="A screenshot of the Anonymous Health App Home, where you're greeted by a CBT Trained AI Companion."
            />
          </VideoContainerPoster>
        </VideoContainer>
        <div className="blurred-orb brand"></div>
        <div className="blurred-orb purple"></div>
        <div className="wrap">
          <HeroLogo />
          <img
            src={AppHeroImage}
            alt="A screenshot of the Anonymous Health App Home, where you're greeted by a CBT Trained AI Companion."
          />
          <div className='app-hero-content'>
            <h1>Begin your journey <strong><em>today</em></strong> to overcome unwanted habits with Anonymous Health.</h1>
            <AppStoreNav />
          </div>
        </div>
      </AppHeroContainer>
    );
  }
}

export default AppHero;

import React, { Component, createRef } from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow } from "../utilities/mediaQuery";

// Images
import AHCharlieEngle from "../assets/images/AH-Charlie-Engle.webp";
import AHHilaryPhelps from "../assets/images/AH-Hilary-Phelps.webp";

// Components
import CalloutText from './CalloutText';

// Styled Components
const InspirationalContentCalloutContainer = styled.section`
  .wrap {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: 2em;

    ${queryBelow("bigPhone", css`
      align-items: flex-start;
      flex-direction: column;
    `)}
  }
`;

const InspirationalContentImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${queryBelow("bigPhone", css`
    justify-content: center;
    margin-inline: auto;
  `)}

  img {
    max-height: 100vh;
    max-width: 16em;
    height: auto;
    margin-inline: -2em;
    width: 100%;
    position: relative;
    opacity: 0;
    transform: translateY(8em);
    transition: transform 1.5s ease, opacity 0.5s ease;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-of-type(even) {
      top: -1em;
    }

    &:nth-of-type(odd) {
      top: 1em;
    }
  }
`;

// InspirationalContentCallout Component
class InspirationalContentCallout extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      visibleImages: [false, false], // Tracks visibility for both images
    };
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.triggerSequentialFadeIn();
          this.observer.disconnect(); // Stop observing after triggering animations
        }
      },
      {
        root: null,
        threshold: 0.6,
      }
    );

    if (this.containerRef.current) {
      this.observer.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  triggerSequentialFadeIn = () => {
    const { visibleImages } = this.state;

    visibleImages.forEach((_, index) => {
      setTimeout(() => {
        this.setState((prevState) => {
          const updatedVisibleImages = [...prevState.visibleImages];
          updatedVisibleImages[index] = true;
          return { visibleImages: updatedVisibleImages };
        });
      }, index * 100);
    });
  };

  render() {
    const { visibleImages } = this.state;
    const { className } = this.props;

    return (
      <InspirationalContentCalloutContainer className={className} ref={this.containerRef}>
        <div className="wrap">
          <CalloutText
            className="no-margin-bottom"
            text="<strong><em>Get motivated</em></strong> and stay on track with our inspirational content library."
          />
          <InspirationalContentImages>
            {[AHHilaryPhelps, AHCharlieEngle].map((src, index) => (
              <img
                key={index}
                alt={`Anonymous Health App - Inspirational content ${index + 1}`}
                src={src}
                loading="lazy"
                className={visibleImages[index] ? 'visible' : ''}
              />
            ))}
          </InspirationalContentImages>
        </div>
      </InspirationalContentCalloutContainer>
    );
  }
}

export default InspirationalContentCallout;
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { queryBelow } from "../utilities/mediaQuery"

// Images
import AppleAppStoreImage from "../assets/images/AppleAppStoreBadge.svg"
import GooglePlayImage from "../assets/images/GooglePlayStoreBadge.svg"

// Styled Components
const AppStoreNavContainer = styled.div`
  display: flex;
  gap: 1em;
  position: relative;

  ${queryBelow("bigPhone", css`
    justify-content: center;
  `)}

  a {
    user-select: none;
    display: inline-flex;

    img {
      height: 3.4em;
    }
  }
`

// AppStoreNav
class AppStoreNav extends Component {
  render() {
    const { className } = this.props; // Destructure className from props

    return(
      <AppStoreNavContainer className={className}>
        <a href="https://apps.apple.com/us/app/anonymous-health/id6449686679" title="Download the Anonymous Health App on the Apple App Store" target="_blank" rel="noreferrer">
          <img alt='Download the Balance App on the Apple App Store' src={AppleAppStoreImage} />
        </a>
        <a href='https://play.google.com/store/apps/details?id=health.drumbeat.prod&pli=1' target="_blank" rel="noreferrer">
          <img alt='Get the Anonymous Health App on Google Play' src={GooglePlayImage} />
        </a>
      </AppStoreNavContainer>
    );
  }
}

export default AppStoreNav;
